//
//
//
//
//
//
//
//
//
//
//

import DataClient from '../api/DataClient';

export default {
  data() {
    return {
      email: '',
      message: ''
    };
  },
  methods: {
    async submitEmail() {
      const client = new DataClient();
      try {
        const response = await client.post('https://incrementalhealth.test/api/password/email', { email: this.email });
        this.message = response.message;
      } catch (error) {
        console.error(error);
        this.message = 'An error occurred.';
      }
    }
  }
};
